import { VersionIndicator } from "@/app/components/version-indicator"
import { format } from "date-fns"
export default function Footer() {
  return (
    <div className="static inset-x-0 bottom-0 z-20 grid h-[30] grid-cols-3 gap-4 bg-blue-950 p-2 text-white dark:bg-blue-950">
      <div className="mx-4">
        <h2 className="mb-1 text-sm font-bold">ACCESSIBILITY/SECTION 508</h2>
        <p className="text-xs">
          The U.S. Department of Energy is committed to making its electronic
          and information technologies accessible to individuals with
          disabilities in accordance with Section 508 of the Rehabilitation Act
          (29 U.S.C. 794d), as amended in 1998.
        </p>
      </div>
      <div className="mx-4">
        <h2 className="mb-1 text-sm font-bold">FEEDBACK</h2>
        <p className="text-xs">
          Have questions or feedback? Please use the Feedback tool on the right
          of the page to share your thoughts.
        </p>
      </div>
      <div className="mx-4">
        <h2 className="mb-1 text-sm font-bold">CONTACT US</h2>
        <p className="text-xs">
          For tech support, please send us an email at{" "}
          <a
            href="mailto:support@pars.doe.gov"
            className="text-blue-300 hover:text-blue-100"
          >
            support@pars.doe.gov
          </a>{" "}
          or head to{" "}
          <a
            href="https://support.pars.doe.gov"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-300 hover:text-blue-100"
          >
            {`support.pars.doe.gov.`}
          </a>
        </p>
        <div className="mt-3 flex flex-row text-xs">
          {"PARS "}
          <VersionIndicator />{" "}
          <div className="ml-3">
            © 2013-{format(new Date(), "yyyy")} DOE Office of Project
            Management
          </div>
        </div>
      </div>
    </div>
  )
}
